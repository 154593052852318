<template>
  <div>
    <h4 class="font-weight-bold pb-1 indigo--text">Gestión</h4>
    <h5 class="font-weight-light pb-3">Gestión de Facturación</h5>

    <v-card class="elevation-15" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Facturación
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = 'Pendientes'">Pendientes facturar</v-tab>
        <!--<v-tab @click="bEstado = 'Facturas'">Facturas</v-tab>-->
        <v-tab @click="bEstado = 'FacturasProy'">Facturas/Proyecto</v-tab>
        <v-tab @click="bEstado = 'cargaEstadoCuenta'">Cargar Estado Cuenta</v-tab>
        <!--<v-tab @click="bEstado = 'EstadoAvance'">Estado avance Proy.</v-tab>-->
      </v-tabs>
      <v-tabs-items v-model="bEstado">
        <v-tab-item value="cargaEstadoCuenta">
          <v-card class="mb-6 mt-6 ml-6 mr-6 elevation-0">
            <v-row>
              <v-col cols="12" lg="6">
                <v-card>
                  <v-card-title class="blue white--text">
                    Cargar Estado de cuenta
                  </v-card-title>
                  <v-card-text>
                    <v-file-input
                      v-model="archivotemp_presupuesto"
                      hide-details
                      label="Estado de cuenta"
                      type="file"
                      ref="file_presupuesto"
                      @change="selectFile_presupuesto"
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="blue white--text" @click="cargaEstadoCuenta()"
                      >enviar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card>
                  <v-card-title class="deep-purple white--text">
                    Resultado de carga
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table v-if="resultado_carga.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Fecha Banco</th>
                            <th class="text-left">Nº Factura</th>
                            <th class="text-left">Monto</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in resultado_carga" :key="item.monto">
                            <td>{{ item.fecha_carga }}</td>
                            <td>{{ item.nroFactura }}</td>
                            <td>{{ item.monto_carga }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div v-else style="text-align: center">
                      {{ resultado_carga_msj }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="bEstado">
        <v-tab-item value="Pendientes">
          <v-data-table
            class="mt-3"
            :headers="headers_pendientes"
            :items="filteredItems_pendientes"
            :no-results-text="noresult"
            no-data-text="Sin resultados que mostrar"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :custom-sort="customSort"
            :footer-props="{
              'items-per-page-text': 'Resultados por página',
              'items-per-page-all-text': 'Todos',
            }"
          >
            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
            </template>
            <template v-slot:item.accion="{ item }">
              <v-icon
                small
                class="mr-2 blue--text text--darken-5"
                @click="asignarFactura(item.id_concepto, item.id_mandante)"
              >
                mdi-text-box-plus-outline
              </v-icon>
              <v-icon
                small
                v-if="usuarioDB.data.role == 'ADMIN'"
                class="mr-2 red--text text--darken-4"
                @click="deleteItem(item._id)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.exento="{ item }">
              <span v-if="item.exento">Exento</span>
              <span v-else>Afecto</span>
            </template>

            <template v-slot:item.nro_proyecto="{ item }">
              <router-link :to="{ name: 'proyecto', params: { id: item._id } }">
                {{ item.nro_proyecto }}
              </router-link>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip class="ma-2" dark small :color="getEstados(item.status)">
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.responsable="{ item }">
              <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
              <v-chip
                class="ma-2"
                dark
                small
                :color="item.color"
                data-toggle="tooltip"
                data-placement="bottom"
                :title="item.responsableCompleto"
              >
                {{ item.responsable }}
              </v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="Facturas">
          <v-card class="elevation-0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                @click="$router.push({ name: 'factura' })"
                >Nueva Factura</v-btn
              >
            </v-card-title>
          </v-card>

          <v-data-table
            class="mt-3"
            :headers="headers_facturas"
            :items="filteredItems_facturas"
            :no-results-text="noresult"
            no-data-text="Sin resultados que mostrar"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :footer-props="{
              'items-per-page-text': 'Resultados por página',
              'items-per-page-all-text': 'Todos',
            }"
          >
            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
            </template>
            <template v-slot:item.accion="{ item }">
              <v-icon
                small
                class="mr-2 blue--text text--darken-5"
                @click="$router.push({ name: 'factura', params: { id: item._id } })"
              >
                mdi-pencil
              </v-icon>
              <!--<v-icon
                small
                v-if="usuarioDB.data.role == 'ADMIN'"
                class="mr-2 red--text text--darken-4"
                @click="deleteItem(item._id)"
              >
                mdi-delete
              </v-icon>-->
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip class="ma-2" dark small :color="getEstadosFactura(item.status)">
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.responsable="{ item }">
              <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
              <v-chip
                class="ma-2"
                dark
                small
                :color="item.color"
                data-toggle="tooltip"
                data-placement="bottom"
                :title="item.responsableCompleto"
              >
                {{ item.responsable }}
              </v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="FacturasProy">
          <v-data-table
            class="mt-3"
            :headers="headers_facturas_proy"
            :items="filteredItems_facturas_proy"
            :no-results-text="noresult"
            no-data-text="Sin resultados que mostrar"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :custom-sort="customSort"
            :footer-props="{
              'items-per-page-text': 'Resultados por página',
              'items-per-page-all-text': 'Todos',
            }"
          >
            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
            </template>
            <template v-slot:item.accion="{ item }">
              <v-icon
                small
                class="mr-2 blue--text text--darken-5"
                @click="asignarFactura(item.id_concepto, item.id_mandante)"
              >
                mdi-text-box-plus-outline
              </v-icon>
              <v-icon
                small
                v-if="usuarioDB.data.role == 'ADMIN'"
                class="mr-2 red--text text--darken-4"
                @click="deleteItem(item._id)"
              >
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:item.status_factura="{ item }">
              <v-chip
                class="ma-2"
                dark
                small
                :color="getEstadosFactura(item.status_factura)"
              >
                {{ item.status_factura }}
              </v-chip>
            </template>
            <template v-slot:item.nro_factura="{ item }">
              <router-link :to="{ name: 'factura', params: { id: item.id_factura } }">
                {{ item.nro_factura }}
              </router-link>
            </template>
            <template v-slot:item.nro_proyecto="{ item }">
              <router-link :to="{ name: 'proyecto', params: { id: item._id } }">
                {{ item.nro_proyecto }}
              </router-link>
            </template>
            <template v-slot:item.responsable="{ item }">
              <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
              <v-chip
                class="ma-2"
                dark
                small
                :color="item.color"
                data-toggle="tooltip"
                data-placement="bottom"
                :title="item.responsableCompleto"
              >
                {{ item.responsable }}
              </v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="EstadoAvance"> EstadoAvance </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-snackbar v-model="snackbar" color="green" dark timeout="1500" align="center">
      URL copiada exitosamente!
    </v-snackbar>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogBuscarFactura"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text">
            Buscar Factura {{ idConceptoAsignar }} - {{ nroMandante }} -
          </v-card-title>
          <v-card-text class="mt-4">
            <v-text-field
              label="Numero de factura"
              v-model="NroFactura_asignar"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>
          <v-card-text>
            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha factura"
                  v-model="fecha_factura_asignar"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  :rules="basic_validation_rules_input"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha_factura_asignar"
                locale="es-es"
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-text-field
              label="Monto Factura"
              v-model="MontoFactura_asignar"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogBuscarFactura = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="asignarFacturaSend()"
              class="mb-2 indigo--text text--lighten-5 grey"
              v-if="NroFactura_asignar == ''"
              disabled
              >Asignar Factura</v-btn
            >
            <v-btn
              text
              @click="asignarFacturaSend()"
              class="mb-2 indigo--text text--lighten-5 primary"
              v-else
              >Asignar Factura</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

//Definimos la clase de los elementos

export default {
  data() {
    return {
      archivotemp_presupuesto: null,
      files_presupuesto: null,
      procesado: false,
      idConceptoAsignar: "",
      MontoFactura_asignar: "",
      idConceptoProyecto: "",
      menu4: false,
      nroMandante: "",
      NroFactura_asignar: "",
      fecha_factura_asignar: "",

      isLoadingResultProjects: false,
      dialogBuscarFactura: false,
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,

      bEstado: "Pendientes",
      tipo: [
        {
          tipo: "1",
          text: "Proyectos",
        },
        {
          tipo: "2",
          text: "ITO",
        },
      ],
      tipos_facturas: [
        {
          tipo: "1",
          text: "Factura",
        },
        {
          tipo: "2",
          text: "NC",
        },
      ],
      tipos_moneda: [
        {
          tipo: 1,
          text: "U.F",
        },
        {
          tipo: 2,
          text: "Pesos Chilenos",
        },
      ],
      estados: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Aprobado",
        },
        {
          tipo: 3,
          text: "Rechazado",
        },
      ],
      estados_proyecto: [
        {
          tipo: 1,
          text: "Activo",
        },
        {
          tipo: 2,
          text: "Congelado",
        },
        {
          tipo: 3,
          text: "Finalizado",
        },
      ],
      estados_concepto: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Realizado",
        },
        {
          tipo: 3,
          text: "Facturado",
        },
      ],
      estados_factura: [
        {
          tipo: 1,
          text: "Facturado",
        },
        {
          tipo: 2,
          text: "Pagado",
        },
        {
          tipo: 3,
          text: "Anulado",
        },
      ],
      headers_pendientes: [
        {
          text: "Nº Pyto",
          align: "start",
          sortable: true,
          value: "nro_proyecto",
          width: "20%",
        },

        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
          width: "20%",
        },
        {
          text: "Mandante",
          value: "mandante",
          width: "20%",
        },
        {
          text: "Rut",
          value: "mandante_rut",
          width: "15%",
        },
        {
          text: "Concepto",
          value: "concepto",
          width: "20%",
        },
        {
          text: "Resp.",
          value: "responsable",
          width: "1%",
        },
        {
          text: "Valor",
          value: "valor",
        },
        {
          text: "Imp",
          value: "exento",
        },

        {
          text: "Fecha realización",
          value: "fecha_realizacion",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
        },
      ],

      headers_facturas: [
        {
          text: "Nº Documento",
          align: "start",
          sortable: true,
          value: "nro_documento",
          width: "10%",
        },
        {
          text: "Tipo Documento",
          sortable: false,
          value: "tipo",
        },
        {
          text: "Fecha Documento",
          value: "fecha_factura",
        },
        {
          text: "Detalle",
          align: "start",
          sortable: false,
          value: "detalle",
        },
        {
          text: "Mandante",
          value: "mandante",
        },
        {
          text: "Monto",
          value: "monto",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Accion",
          value: "accion",
          sortable: "false",
        },
      ],
      headers_facturas_proy: [
        {
          text: "Nº Pyto",
          align: "start",
          sortable: true,
          value: "nro_proyecto",
          width: "15%",
        },

        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
          width: "20%",
        },
        {
          text: "Mandante",
          value: "mandante",
          width: "20%",
        },
        {
          text: "Concepto",
          value: "concepto",

          width: "20%",
        },
        {
          text: "Resp.",
          value: "responsable",
          width: "1%",
        },
        {
          text: "Fecha Factura",
          value: "fecha_factura",
        },

        {
          text: "Nº Factura",
          value: "nro_factura",
        },
        {
          text: "Monto Factura",
          value: "monto_factura",
        },
        {
          text: "status",
          value: "status_factura",
        },
      ],
      resultados_pendientes: [],
      resultados_facturas: [],
      resultados_facturas_proy: [],
      resultado_carga: [],
      resultado_carga_msj: "Por favor cargar archivo",
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems_pendientes() {
      /*if (this.bEstado == "Todos") {
        return this.desserts;
      } else {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado;
        });
      }*/
      //if (this.bEstado == "Facturas") this.headers.push({ text: "f", value: "f" });
      return this.resultados_pendientes;

      //return this.desserts;
    },
    filteredItems_facturas() {
      /*if (this.bEstado == "Todos") {
        return this.desserts;
      } else {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado;
        });
      }*/
      //if (this.bEstado == "Facturas") this.headers.push({ text: "f", value: "f" });
      return this.resultados_facturas;

      //return this.desserts;
    },
    filteredItems_facturas_proy() {
      /*if (this.bEstado == "Todos") {
        return this.desserts;
      } else {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado;
        });
      }*/
      //if (this.bEstado == "Facturas") this.headers.push({ text: "f", value: "f" });
      return this.resultados_facturas_proy;

      //return this.desserts;
    },
  },
  methods: {
    editItem(id) {
      this.$router.push({
        name: "proyecto",
        params: {
          id: id,
        },
      });
    },
    selectFile_presupuesto(file) {
      this.files_presupuesto = file;
    },
    async cargaEstadoCuenta() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      const formData = new FormData();
      formData.append("file", this.files_presupuesto);
      await axios
        .put("/facturas/subirEstadoCuenta", formData, config)
        .then(async (response) => {
          //console.log(response);
          this.resultado_carga = response.data;
          if (response.data.length == 0) {
            this.resultado_carga_msj = "No hubo coincidencia entre Movimiento/Factura";
          }
          this.procesado = true;
          this.files_presupuesto = null;
          this.archivotemp_presupuesto = null;
          await this.getDataPendientes();

          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          //console.log(e);
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    asignarFactura(idConcepto, idMandante) {
      this.dialogBuscarFactura = true;
      this.idConceptoAsignar = idConcepto;
      this.MontoFactura_asignar = "";
      this.idConceptoProyecto = "";
      this.nroMandante = idMandante;
      this.NroFactura_asignar = "";
      this.fecha_factura_asignar = "";
    },
    async asignarFacturaSend() {
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .put(
          "/facturas/asignarFactura/",
          {
            idConcepto: this.idConceptoAsignar,
            MontoAsignar: this.MontoFactura_asignar,
            idConceptoProyecto: this.idConceptoProyecto,
            NroFactura_asignar: this.NroFactura_asignar,
            nroMandante: this.nroMandante,
            fecha_factura_asignar: this.fecha_factura_asignar,
          },
          config
        )
        .then(async (response2) => {
          /*this.$router.push({
                name: "presupuesto",
                params: {
                  id: this.idDato,
                },
              });*/
          this.idDato = this.idDato;
          this.getDataPendientes();
          this.dialogBuscarFactura = false;
          this.idConceptoAsignar = "";
          this.FacturaAsignar = "";
          this.MontoAsignar = "";
          this.idConceptoProyecto = "";

          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getDataPendientes() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      let filtro = this.$options.filters;

      axios.get("/proyectos/todosSeguimientos/", config).then((response) => {
        //console.log()
        //console.log(response);
        var dataResponse = response.data;
        var responsefinal = [];
        for (let dato in dataResponse) {
          if(dataResponse[dato].presupuestos.length > 0){

            for (let concepto of dataResponse[dato].condiciones) {
              let nro_factura_field = "";
              let monto_factura = "";
              let status_factura = "";
              let fecha_factura = "";
              let id_factura = "";
              //let id_presupuesto = "";
              try {
                nro_factura_field = concepto.nro_factura.nro_documento;
                monto_factura = concepto.nro_factura.monto;
                status_factura = concepto.nro_factura.status;
                fecha_factura = filtro.fecha_normal(concepto.nro_factura.fecha_factura);
                id_factura = concepto.nro_factura._id;
                //id_presupuesto =
              } catch (errorx) {}

              let presupuestos = dataResponse[dato].presupuestos || [];
              let presupuesto = presupuestos.length > 0 ? presupuestos[0] : {};
              if(dataResponse[dato].presupuestos.length < 1){
                console.log(dataResponse[dato]);
              }
              let mandante = presupuesto.mandante || {};

              responsefinal.push({
                _id: dataResponse[dato]._id,
                nombre: dataResponse[dato].nombre,
                nro_proyecto: dataResponse[dato].nro_proyecto,

                mandante: mandante.nombre || "Sin mandante",
                id_mandante: mandante._id || "Sin ID",
                mandante_rut: mandante.rut || "Sin RUT",
                //responsable: dataResponse[dato].encargado.nombre,
                responsable: filtro.sigla(dataResponse[dato].encargado?.nombre) || 'SR',
                responsableCompleto: dataResponse[dato].encargado?.nombre || 'SR',
                color: dataResponse[dato].encargado?.color || '#FF0000',
                concepto: concepto.concepto,
                valor: filtro.formato_numero2(concepto.monto),
                id_concepto: concepto._id,
                fecha_estimada_realizacion: filtro.fecha_normal(
                  concepto.fecha_estimada_realizacion
                ),
                fecha_realizacion: filtro.fecha_normal(concepto.fecha_realizacion),
                monto_cargado: filtro.formato_numero2(concepto.monto_cargado),
                nro_factura: nro_factura_field,
                monto_factura: filtro.formato_numero2(monto_factura),
                fecha_factura: fecha_factura,
                id_factura: id_factura,
                exento: dataResponse[dato].presupuestos[0].exento,

                //nro_factura: filtro.fecha_normal(concepto.fecha_realizacion),
                status: filtro.filtro_status(concepto.estado, this.estados_concepto),

                status_factura: filtro.filtro_status(status_factura, this.estados_factura),
              });
            }
          }
        }
        //console.log(responsefinal);
        this.resultados_pendientes = responsefinal.filter((e) => e.status == "Realizado");
        this.resultados_facturas_proy = responsefinal.filter(
          (e) => e.status == "Facturado"
        );
        //console.log(responsefinal);

        this.loading = false;
      });
    },

    getDataFacturas() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      let filtro = this.$options.filters;

      axios.get("/facturas/factura/", config).then((response) => {
        //console.log(response);
        var dataResponse = response.data;

        var responsefinal = [];
        for (let dato in dataResponse) {
          responsefinal.push({
            _id: dataResponse[dato]._id,
            nro_documento: dataResponse[dato].nro_documento,
            nro_factura_mandante:
              dataResponse[dato].nro_documento +
              " - " +
              dataResponse[dato].mandante?.nombre,
            tipo: filtro.filtro_tipos(dataResponse[dato].tipo, this.tipos_facturas),
            fecha_factura: filtro.fecha_normal(dataResponse[dato].fecha_factura),
            detalle: dataResponse[dato].detalle,
            mandante: dataResponse[dato].mandante?.nombre,
            monto: filtro.formato_numero2(dataResponse[dato].monto),
            conceptos: dataResponse[dato].conceptos,
            status: filtro.filtro_status(dataResponse[dato].status, this.estados_factura),
            fecha_pago: filtro.fecha_normal(dataResponse[dato].fecha_pago),
            responsable: dataResponse[dato].responsable?.nombre,
          });
        }
        /* for (let dato in dataResponse) {
          for (let concepto of dataResponse[dato].condiciones)
            responsefinal.push({
              _id: dataResponse[dato]._id,
              nro_documento: dataResponse[dato].nro_documento,
              tipo: dataResponse[dato].tipo,

              mandante: dataResponse[dato].presupuestos[0].mandante.nombre,
              responsable: dataResponse[dato].encargado.nombre,
              concepto: concepto.concepto,
              valor: concepto.monto,
              id_concepto: concepto._id,
              fecha_estimada_realizacion: filtro.fecha_normal(
                concepto.fecha_estimada_realizacion
              ),
              fecha_realizacion: filtro.fecha_normal(concepto.fecha_realizacion),
              status: filtro.filtro_status(concepto.estado, this.estados_concepto),
            });
          //}
        } */

        this.resultados_facturas = responsefinal; //.filter((e) => e.status == "Realizado");
        //console.log(responsefinal);

        this.loading = false;
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      let filtro = this.$options.filters;

      axios.get("/proyectos/proyecto/", config).then((response) => {
        //this.desserts = response.data;
        //console.log(response.data);
        var dataResponse = response.data;
        var responsefinal = [];
        for (let dato in dataResponse) {
          //console.log(dataResponse[dato].presupuestos);
          for (let pres of dataResponse[dato].presupuestos) {
            //console.log(pres);
            pres.status = filtro.filtro_status(pres.status, this.estados);
            pres.fecha_envio = filtro.fecha_normal(pres.fecha_envio);
            pres.tipo = filtro.filtro_tipos(pres.tipo, this.tipo);
            pres.valor = filtro.filtro_valor(
              filtro.formato_numero(pres.valor),
              pres.tipo_moneda
            );

            responsefinal.push({
              _id: dataResponse[dato]._id,
              nombre: dataResponse[dato].nombre,
              nro_proyecto: dataResponse[dato].nro_proyecto,
              nro_presupuesto: pres.nro_presupuesto,
              tipo: pres.tipo,
              mandante: pres.mandante?.nombre,
              valor: dataResponse[dato].monto_documento,
              status: filtro.filtro_status(
                dataResponse[dato].status,
                this.estados_proyecto
              ),
            });
          }
        }
        this.desserts = responsefinal;
        //console.log(this.desserts);
        this.loading = false;
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/presupuestos/presupuesto/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == "Congelado") return "light-blue";
      else if (estado == "Activo") return "light-green darken-2";
      else if (estado == "Finalizado") return "cyan";
    },
    getEstadosFactura(estado) {
      if (estado == "Facturado") return "light-green darken-2";
      else if (estado == "Pagado") return "brown";
      else if (estado == "Anulado") return "gray";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    customSort(items, index, isDesc) {
      let comparador = (fecha1, fecha2, desc) => {
        if (!fecha1) {
          return 1;
        }
        if (!fecha2) {
          return -1;
        }

        let fecha1Splitted = fecha1.split("/");
        let fecha2Splitted = fecha2.split("/");
        let fecha11 =
          fecha1Splitted[2] + "-" + fecha1Splitted[1] + "-" + fecha1Splitted[0];
        let fecha22 =
          fecha2Splitted[2] + "-" + fecha2Splitted[1] + "-" + fecha2Splitted[0];
        let date1 = new Date(fecha11);
        let date2 = new Date(fecha22);
        return date1 < date2 ? 1 : -1;
      };

      let nros_proyectos = (a, b, isDesc) => {
        let va = a.replace(/[^0-9]+/g, "");
        let vb = b.replace(/[^0-9]+/g, "");
        if (!isDesc) {
          if (va < vb) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (vb < va) {
            return 1;
          } else {
            return -1;
          }
        }
      };

      items.sort((a, b) => {
        if (index[0] === "fecha_estimada_realizacion") {
          if (!isDesc[0]) {
            return comparador(
              a.fecha_estimada_realizacion,
              b.fecha_estimada_realizacion,
              2
            );
          } else {
            return comparador(
              b.fecha_estimada_realizacion,
              a.fecha_estimada_realizacion,
              1
            );
          }
        } else if (index[0] === "nro_proyecto") {
          return nros_proyectos(a.nro_proyecto, b.nro_proyecto, isDesc[0]);
        } else if (index[0] === "nro_presupuesto") {
          return nros_proyectos(a.nro_presupuesto, b.nro_presupuesto, isDesc[0]);
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
  created() {
    this.getDataPendientes();
    this.getDataFacturas();
    //this.getData();
  },
  filters: {
    filtro_tipos: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0 && valor) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_status: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        //console.log("si");
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    formato_numero(valor) {
      return new Intl.NumberFormat("de-DE").format(Math.round(valor).toFixed(0));
    },
    formato_numero2(valor) {
      //console.log(valor);
      return new Intl.NumberFormat("de-DE").format(valor);
    },
    sigla(valor) {
      if(valor){
        let splitted = valor.split(" ");
        let ret = "";
        for (let i = 0; i < splitted.length; i++) {
          ret = ret + splitted[i][0];
        }

        return ret.toLocaleUpperCase();
      }else{
        return '--'
      }
    },
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"font-weight-bold pb-1 indigo--text"},[_vm._v("Gestión")]),_c('h5',{staticClass:"font-weight-light pb-3"},[_vm._v("Gestión de Facturación")]),_c('v-card',{staticClass:"elevation-15",attrs:{"id":"cardborde"}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"blue accent-4"}}),_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v(" Facturación "),_c('v-spacer'),_c('v-text-field',{staticClass:"m-2",attrs:{"append-icon":"mdi-magnify","color":"primary","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-tabs',{attrs:{"right":""}},[_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'Pendientes'}}},[_vm._v("Pendientes facturar")]),_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'FacturasProy'}}},[_vm._v("Facturas/Proyecto")]),_c('v-tab',{on:{"click":function($event){_vm.bEstado = 'cargaEstadoCuenta'}}},[_vm._v("Cargar Estado Cuenta")])],1),_c('v-tabs-items',{model:{value:(_vm.bEstado),callback:function ($$v) {_vm.bEstado=$$v},expression:"bEstado"}},[_c('v-tab-item',{attrs:{"value":"cargaEstadoCuenta"}},[_c('v-card',{staticClass:"mb-6 mt-6 ml-6 mr-6 elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"blue white--text"},[_vm._v(" Cargar Estado de cuenta ")]),_c('v-card-text',[_c('v-file-input',{ref:"file_presupuesto",attrs:{"hide-details":"","label":"Estado de cuenta","type":"file","prepend-icon":"mdi-paperclip"},on:{"change":_vm.selectFile_presupuesto},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.archivotemp_presupuesto),callback:function ($$v) {_vm.archivotemp_presupuesto=$$v},expression:"archivotemp_presupuesto"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"blue white--text",on:{"click":function($event){return _vm.cargaEstadoCuenta()}}},[_vm._v("enviar")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"deep-purple white--text"},[_vm._v(" Resultado de carga ")]),_c('v-card-text',[(_vm.resultado_carga.length > 0)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Fecha Banco")]),_c('th',{staticClass:"text-left"},[_vm._v("Nº Factura")]),_c('th',{staticClass:"text-left"},[_vm._v("Monto")])])]),_c('tbody',_vm._l((_vm.resultado_carga),function(item){return _c('tr',{key:item.monto},[_c('td',[_vm._v(_vm._s(item.fecha_carga))]),_c('td',[_vm._v(_vm._s(item.nroFactura))]),_c('td',[_vm._v(_vm._s(item.monto_carga))])])}),0)]},proxy:true}],null,false,3156893477)}):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.resultado_carga_msj)+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.bEstado),callback:function ($$v) {_vm.bEstado=$$v},expression:"bEstado"}},[_c('v-tab-item',{attrs:{"value":"Pendientes"}},[_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers_pendientes,"items":_vm.filteredItems_pendientes,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"custom-sort":_vm.customSort,"footer-props":{
            'items-per-page-text': 'Resultados por página',
            'items-per-page-all-text': 'Todos',
          }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.url",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verURL(item.url)}}},[_vm._v(" mdi-web ")])]}},{key:"item.accion",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text text--darken-5",attrs:{"small":""},on:{"click":function($event){return _vm.asignarFactura(item.id_concepto, item.id_mandante)}}},[_vm._v(" mdi-text-box-plus-outline ")]),(_vm.usuarioDB.data.role == 'ADMIN')?_c('v-icon',{staticClass:"mr-2 red--text text--darken-4",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.exento",fn:function(ref){
          var item = ref.item;
return [(item.exento)?_c('span',[_vm._v("Exento")]):_c('span',[_vm._v("Afecto")])]}},{key:"item.nro_proyecto",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'proyecto', params: { id: item._id } }}},[_vm._v(" "+_vm._s(item.nro_proyecto)+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":_vm.getEstados(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.responsable",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":item.color,"data-toggle":"tooltip","data-placement":"bottom","title":item.responsableCompleto}},[_vm._v(" "+_vm._s(item.responsable)+" ")])]}}])})],1),_c('v-tab-item',{attrs:{"value":"Facturas"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"color":"indigo lighten-5"},on:{"click":function($event){return _vm.$router.push({ name: 'factura' })}}},[_vm._v("Nueva Factura")])],1)],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers_facturas,"items":_vm.filteredItems_facturas,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"footer-props":{
            'items-per-page-text': 'Resultados por página',
            'items-per-page-all-text': 'Todos',
          }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.url",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verURL(item.url)}}},[_vm._v(" mdi-web ")])]}},{key:"item.accion",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text text--darken-5",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'factura', params: { id: item._id } })}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":_vm.getEstadosFactura(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.responsable",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":item.color,"data-toggle":"tooltip","data-placement":"bottom","title":item.responsableCompleto}},[_vm._v(" "+_vm._s(item.responsable)+" ")])]}}])})],1),_c('v-tab-item',{attrs:{"value":"FacturasProy"}},[_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers_facturas_proy,"items":_vm.filteredItems_facturas_proy,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"custom-sort":_vm.customSort,"footer-props":{
            'items-per-page-text': 'Resultados por página',
            'items-per-page-all-text': 'Todos',
          }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.url",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verURL(item.url)}}},[_vm._v(" mdi-web ")])]}},{key:"item.accion",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text text--darken-5",attrs:{"small":""},on:{"click":function($event){return _vm.asignarFactura(item.id_concepto, item.id_mandante)}}},[_vm._v(" mdi-text-box-plus-outline ")]),(_vm.usuarioDB.data.role == 'ADMIN')?_c('v-icon',{staticClass:"mr-2 red--text text--darken-4",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.status_factura",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":_vm.getEstadosFactura(item.status_factura)}},[_vm._v(" "+_vm._s(item.status_factura)+" ")])]}},{key:"item.nro_factura",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'factura', params: { id: item.id_factura } }}},[_vm._v(" "+_vm._s(item.nro_factura)+" ")])]}},{key:"item.nro_proyecto",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'proyecto', params: { id: item._id } }}},[_vm._v(" "+_vm._s(item.nro_proyecto)+" ")])]}},{key:"item.responsable",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","small":"","color":item.color,"data-toggle":"tooltip","data-placement":"bottom","title":item.responsableCompleto}},[_vm._v(" "+_vm._s(item.responsable)+" ")])]}}])})],1),_c('v-tab-item',{attrs:{"value":"EstadoAvance"}},[_vm._v(" EstadoAvance ")])],1)],1),_c('v-snackbar',{attrs:{"color":"green","dark":"","timeout":"1500","align":"center"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" URL copiada exitosamente! ")]),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Buscar Factura "+_vm._s(_vm.idConceptoAsignar)+" - "+_vm._s(_vm.nroMandante)+" - ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"label":"Numero de factura","outlined":"","hide-details":""},model:{value:(_vm.NroFactura_asignar),callback:function ($$v) {_vm.NroFactura_asignar=$$v},expression:"NroFactura_asignar"}})],1),_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha factura","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_input,"required":""},model:{value:(_vm.fecha_factura_asignar),callback:function ($$v) {_vm.fecha_factura_asignar=$$v},expression:"fecha_factura_asignar"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"locale":"es-es"},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.fecha_factura_asignar),callback:function ($$v) {_vm.fecha_factura_asignar=$$v},expression:"fecha_factura_asignar"}})],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"label":"Monto Factura","outlined":"","hide-details":""},model:{value:(_vm.MontoFactura_asignar),callback:function ($$v) {_vm.MontoFactura_asignar=$$v},expression:"MontoFactura_asignar"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"text":""},on:{"click":function($event){_vm.dialogBuscarFactura = false}}},[_vm._v("Cerrar")]),_c('v-spacer'),(_vm.NroFactura_asignar == '')?_c('v-btn',{staticClass:"mb-2 indigo--text text--lighten-5 grey",attrs:{"text":"","disabled":""},on:{"click":function($event){return _vm.asignarFacturaSend()}}},[_vm._v("Asignar Factura")]):_c('v-btn',{staticClass:"mb-2 indigo--text text--lighten-5 primary",attrs:{"text":""},on:{"click":function($event){return _vm.asignarFacturaSend()}}},[_vm._v("Asignar Factura")])],1)],1)]}}]),model:{value:(_vm.dialogBuscarFactura),callback:function ($$v) {_vm.dialogBuscarFactura=$$v},expression:"dialogBuscarFactura"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }